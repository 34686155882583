<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 평가년도 -->
          <c-datepicker
            label="평가년도"
            default="today"
            type="year"
            name="eiaDate"
            v-model="searchParam.eiaDate"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 작성부서 -->
          <c-dept
            :isFirstValue="false"
            label="작성부서"
            type="search"
            name="eiaDeptCd"
            v-model="searchParam.eiaDeptCd"
          />
        </div>
          <!-- 진행단계 -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="patrolCompleteFlag"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.patrolCompleteFlag"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <!-- 환경영향평가 목록 -->
    <c-table
      ref="table"
      title="환경영향평가 목록"
      rowKey="sopEmergencyTrainingId"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'env-impact-assessment',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'eiaDate',
            field: 'eiaDate',
            // 평가년도
            label: '평가년도',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'eiaTitle',
            field: 'eiaTitle',
            // 제목
            label: '제목',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:200px',
          },
          {
            name: 'eiaDeptName',
            field: 'eiaDeptName',
            // 부서
            label: '작성부서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'firstQuarter',
            field: 'firstQuarter',
            // 1분기
            label: '1분기',
            child: [
              {
                name: 'fstStatusName',
                field: 'fstStatusName',
                label: '진행상태',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
              {
                name: 'isFstFile',
                field: 'isFstFile',
                label: '파일여부',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
            ]
          },
          {
            name: 'secondQuarter',
            field: 'secondQuarter',
            // 2분기
            label: '2분기',
            child: [
              {
                name: 'secStatusName',
                field: 'secStatusName',
                label: '진행상태',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
              {
                name: 'isSecFile',
                field: 'isSecFile',
                label: '파일여부',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
            ]
          },
          {
            name: 'thirdQuarter',
            field: 'thirdQuarter',
            // 3분기
            label: '3분기',
            child: [
              {
                name: 'trdStatusName',
                field: 'trdStatusName',
                label: '진행상태',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
              {
                name: 'isTrdFile',
                field: 'isTrdFile',
                label: '파일여부',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
            ]
          },
          {
            name: 'fourthQuarter',
            field: 'fourthQuarter',
            // 1분기
            label: '4분기',
            child: [
              {
                name: 'frtStatusName',
                field: 'frtStatusName',
                label: '진행상태',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
              {
                name: 'isFrtFile',
                field: 'isFrtFile',
                label: '파일여부',
                align: 'center',
                sortable: true,
                style: 'width:80px',
              },
            ]
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        eiaDate: '',
        eiaDeptCd: '',
      },
      statusItems: [
        { code: "N", codeName: "점검중" },
        { code: "Y", codeName: "점검완료" },
      ],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.eia.assessment.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '환경영향평가 상세'; // 환경영향평가 상세
      this.popupOptions.param = {
        eiaAssessmentId: row ? row.eiaAssessmentId : '',
      };
      this.popupOptions.target = () => import(`${'./envImpactAssessmentDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.width = '90%'
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
